import React, { useState, useEffect,lazy, Suspense, useCallback, useMemo } from 'react';
import { StaticImage } from "gatsby-plugin-image";
import * as styles from '../styles/mainApp.module.css';
import { MdChevronLeft, MdChevronRight, MdHome, MdStairs, MdCalendarToday, MdTimeline } from 'react-icons/md';

import { generateClient } from 'aws-amplify/api';
import { fetchUserAttributes,getCurrentUser} from "@aws-amplify/auth";
import { StairazAuth } from '../components/userAuth.js';
import { createServiceConsultant,updateServiceConsultant,createConsultantConversationHistory,updateConsultantConversationHistory } from '../graphql/mutations.js';
import {serviceConsultantsByUserprofileID,ConsultantConversationHistoriesByServiceconsultantID} from '../graphql/queries.js';
import { v4 as uuidv4 } from 'uuid';
import { format, addDays, parseISO, isWithinInterval } from 'date-fns';

const variableInit = {
  goals: [
    {
      Id: "goal1",
      Goal: "2024 Fall Academic",
      note: "Focus on completing coursework and maintaining a high GPA",
      startDate: "2024-08-01",
      endDate: "2024-12-31",
      Status: 0,
      plan: [
        {
          Id: "plan1",
          planName: "STAT 418 Course",
          Note: "Advanced Machine Learning course",
          Type: "class",
          startDate: "2024-09-01",
          endDate: "2024-12-15",
          Status: 0,
          taskOnce: [
            {
              TaskName: "Midterm Exam",
              Note: "Covers first half of the course material",
              Priority: "High",
              Value: 30,
              date: "2024-10-07",
              startTime: "14:20",
              endTime: "16:00",
              Status: 0
            },
            {
              TaskName: "Midterm Exam 2",
              Note: "Covers first half of the course material",
              Priority: "High",
              Value: 30,
              date: "2024-10-06",
              startTime: "15:00",
              endTime: "17:00",
              Status: 0
            },
            {
              TaskName: "Midterm Exam 3",
              Note: "Covers first half of the course material",
              Priority: "High",
              Value: 30,
              date: "2024-10-06",
              startTime: "15:00",
              endTime: "17:00",
              Status: 0
            }
          ],
          taskRecurring: [
            {
              TaskName: "STAT 418 Lecture",
              Note: "Attend weekly lecture",
              priority: "High",
              startDate: "2024-09-01",
              endDate: "2024-12-15",
              startTime: "10:00",
              endTime: "11:30",
              Status: 0,
              weekdays: ["Mon", "Wed", "Fri"], // New property
              excludeDate: ["2024-10-10", "2024-11-24"] // New property
            }
          ]
        },
        {
          Id: "plan2",
          planName: "Research Project",
          Note: "Collaborate with Prof. Smith on ML project",
          Type: "general",
          startDate: "2024-09-15",
          endDate: "2024-12-10",
          Status: 0,
          taskOnce: [
            {
              TaskName: "Project Proposal Due",
              Note: "Submit 5-page proposal to Prof. Smith",
              Priority: "High",
              Value: 20,
              date: "2024-09-30",
              startTime: "23:59",
              endTime: "23:59",
              Status: 0
            }
          ],
          taskRecurring: [
            {
              TaskName: "Research Team Meeting",
              Note: "Weekly progress update with team",
              priority: "Medium",
              startDate: "2024-09-15",
              endDate: "2024-12-10",
              startTime: "15:00",
              endTime: "16:00",
              Status: 0,
              weekdays: ["Tue", "Thu"], // New property
              excludeDate: ["2024-10-10", "2024-11-24"] // New property
            }
          ]
        }
      ]
    },
    {
      Id: "goal2",
      Goal: "Secure Software Engineering Internship",
      note: "Prepare for and apply to summer internships",
      startDate: "2024-01-01",
      endDate: "2024-05-31",
      Status: 0,
      plan: [
        {
          Id: "plan3",
          planName: "Resume and Cover Letter Preparation",
          Note: "Update and tailor application materials",
          Type: "career",
          startDate: "2024-01-15",
          endDate: "2024-02-15",
          Status: 0,
          taskOnce: [
            {
              TaskName: "Career Center Review",
              Note: "Get resume and cover letter reviewed",
              Priority: "High",
              Value: 15,
              date: "2024-10-07",
              startTime: "13:00",
              endTime: "13:30",
              Status: 0
            }
          ],
          taskRecurring: [
            {
              TaskName: "Job Application Session",
              Note: "Dedicate time to apply for internships",
              priority: "High",
              startDate: "2024-01-15",
              endDate: "2024-02-15",
              startTime: "19:00",
              endTime: "21:00",
              Status: 0,
              weekdays: ["Tue", "Thu"], // New property
              excludeDate: ["2024-01-25", "2024-02-01"] // New property
            }
          ]
        },
        {
          Id: "plan4",
          planName: "Technical Interview Prep",
          Note: "Practice coding problems and system design",
          Type: "career",
          startDate: "2024-02-01",
          endDate: "2024-04-30",
          Status: 0,
          taskOnce: [
            {
              TaskName: "Mock Interview",
              Note: "Schedule a mock interview with a peer",
              Priority: "Medium",
              Value: 10,
              date: "2024-10-07",
              startTime: "12:00",
              endTime: "13:00",
              Status:0
            }
          ],
          taskRecurring: [
            {
              TaskName: "LeetCode Practice",
              Note: "Solve coding problems on LeetCode",
              priority: "Medium",
              startDate: "2024-02-01",
              endDate: "2024-04-30",
              startTime: "20:00",
              endTime: "21:00",
              Status: 0,
              weekdays: ["Mon", "Wed", "Fri"], // New property
              excludeDate: ["2024-02-14", "2024-03-17", "2024-04-01"] // New property
            }
          ]
        }
      ]
    }
  ]
};

const App = ({ signOut, user }) => {

  const StairwayMain = lazy(() => import('../service/stairway/utils/Main.js'));
  const CalendarMain = lazy(() => import('../service/calendar/main.js'));
  const TimelineMain = lazy(() => import('../service/timeline/main.js'));
  const HomeMain = lazy(() => import('../service/home/main.js'));
  const client = generateClient();

  const [userData, setUserData] = useState(variableInit);
  const [userProfileData, setUserProfileData] = useState(null);
  
  const [isLeftPanelExpanded, setIsLeftPanelExpanded] = useState(true);
  const [currentService, setCurrentService] = useState('home');

  const createUserProfileData = async () => {
    try {
      const userData = await fetchUserAttributes();
      setUserProfileData(userData);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  useEffect(() => {
    if (user) {
      createUserProfileData();
      computeRecurringEventDates();
    }
  }, [user]);

  const computeRecurringEventDates = () => {
    const updatedGoals = userData.goals.map(goal => ({
      ...goal,
      plan: goal.plan.map(planItem => ({
        ...planItem,
        taskRecurring: planItem.taskRecurring.map(task => {
          const exactDates = getExactDates(task);
          return { ...task, exactDates };
        })
      }))
    }));

    setUserData(prevState => ({
      ...prevState,
      goals: updatedGoals
    }));
  };

  const getExactDates = (task) => {
    const start = parseISO(task.startDate);
    const end = parseISO(task.endDate);
    const excludeDates = task.excludeDate ? task.excludeDate.map(date => parseISO(date)) : [];
    const weekdayMap = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };

    let currentDate = start;
    const exactDates = [];

    while (currentDate <= end) {
      const dayOfWeek = currentDate.getDay();
      const dayName = Object.keys(weekdayMap).find(key => weekdayMap[key] === dayOfWeek);

      if (
        task.weekdays.includes(dayName) &&
        !excludeDates.some(date => date.getTime() === currentDate.getTime())
      ) {
        exactDates.push(format(currentDate, 'yyyy-MM-dd'));
      }

      currentDate = addDays(currentDate, 1);
    }

    return exactDates;
  };

  const updateUserData = useCallback((updater) => {
    setUserData(prevUserData => {
      const newUserData = JSON.parse(JSON.stringify(prevUserData));
      updater(newUserData);
      return newUserData;
    });
  }, []);

  //   const getExistingServiceConsultant = async (userProfileID) => {
  //     const param = {
  //       'userprofileID':userProfileID,
  //       'filter':{active: {
  //         eq: true 
  //       }
  //     }
  //     const serviceConsultantData = await client.graphql({query: serviceConsultantsByUserprofileID, variables:param} );
  //     if (serviceConsultantData.data.serviceConsultantsByUserprofileID.items.length > 0){
  //     setUserData({...userData,summary_conversation:serviceConsultantData.data.serviceConsultantsByUserprofileID.items[0].summary_conversation,serviceID:serviceConsultantData.data.serviceConsultantsByUserprofileID.items[0].id})
  //   }else{
  //     const id = uuidv4();

  //     const createServiceConsultantData = await client.graphql({query:createServiceConsultant, variables:{input: {
  //       'id':id,
  //       'userprofileID':userProfileID,
  //       'summary_conversation':'',
  //       'active':true
  //     }
  //     }
  //   })
  //   setUserData({...userData,serviceID:id})

  // }}

  const memoizedService = useMemo(() => {
    console.log('Rendering service:', currentService);
    if (!user) {
      return <div>Please log in to access services.</div>;
    }
    switch (currentService) {
      case 'home':
        return <HomeMain client={client} userInfo={userProfileData} userData={userData} updateUserData={updateUserData} />;
      case 'stairway':
        return <StairwayMain client={client} userInfo={userProfileData} />;
      case 'calendar':
        return <CalendarMain client={client} userInfo={userProfileData} userData={userData} setUserData={setUserData} />;
      case 'timeline':
        return <TimelineMain client={client} userInfo={userProfileData} userData={userData} setUserData={setUserData} />;
      default:
        return <div>Select a service</div>;
    }
  }, [currentService, client, userProfileData, userData, updateUserData, setUserData, user]);

  if (!user) {
    return <div>Loading...</div>; // or some loading spinner until the user is confirmed to be logged in
  }

  return (
    <div className={styles.appContainer}>
      <div className={`${styles.leftPanel} ${isLeftPanelExpanded ? styles.leftPanelExpanded : styles.leftPanelCollapsed}`}>
        <div className={styles.leftPanelContent}>
          <div className={styles.logoContainer}>
            <StaticImage
              src="../images/logo.png"
              alt="Company Logo"
              className={styles.logo}
              width={60}
              height={60}
              placeholder="blurred"
            />
            {isLeftPanelExpanded && <span className={styles.companyName}>STAIRAZ</span>}
          </div>
          <ul className={styles.serviceList}>
            <li className={styles.serviceItem} onClick={() => setCurrentService('home')}>
              <MdHome className={styles.serviceIcon} />
              <span className={styles.serviceName}>Home</span>
            </li>
            <li className={styles.serviceItem} onClick={() => setCurrentService('stairway')}>
              <MdStairs className={styles.serviceIcon} />
              <span className={styles.serviceName}>Stairway</span>
            </li>
            <li className={styles.serviceItem} onClick={() => setCurrentService('calendar')}>
              <MdCalendarToday className={styles.serviceIcon} />
              <span className={styles.serviceName}>Calendar</span>
            </li>
            <li className={styles.serviceItem} onClick={() => setCurrentService('timeline')}>
              <MdTimeline className={styles.serviceIcon} />
              <span className={styles.serviceName}>Timeline</span>
            </li>
          </ul>
        </div>
        <button
          className={styles.toggleButton}
          onClick={() => setIsLeftPanelExpanded(!isLeftPanelExpanded)}
        >
          {isLeftPanelExpanded ? <MdChevronLeft /> : <MdChevronRight />}
        </button>
      </div>

      <div className={styles.mainPanel}>
        <Suspense fallback={<div>Loading...</div>}>
          {memoizedService}
        </Suspense>
      </div>
    </div>
  );

};

export default StairazAuth(App);